import React from 'react'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from "../pages/Home";
import Error from './Error';
import User from '../pages/User';

function Route() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
            errorElement: <Error />,
        },
        {
            path: "/user/:id",
            element: <User />,
            errorElement: <Error />,  // Error handling for user profile
        },
    ]);

    return <RouterProvider router={router} />

}

export default Route