import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';

const User = () => {
    const { id } = useParams();
    // const [isInstagramWebview, setIsInstagramWebview] = useState(false);

    useEffect(() => {
        const appLink = `eclosetapp.com://user/${id}`;
        // console.log(id); // Deep link to open the app
        // const appStoreLink = "https://apps.apple.com/us/app/6621264661"; // Fallback to App Store

        // Try opening the app
        window.location.href = appLink;

        // If the app isn't installed, redirect to App Store after 3 seconds
        const timer = setTimeout(() => {
            window.location.href = `https://eclosetapp.com/fallback-page.html?id=${id}`;
        }, 3000);

        return () => clearTimeout(timer); // Cleanup timeout when unmounting
    }, [id]);

    const openAppInInstagram = (e) => {
        e.preventDefault(); // Prevent default behavior
        // console.log("Button clicked");
        window.location.href = `https://eclosetapp.com/fallback-page.html?id=${id}`;
    };

    return (
        <div className="h-full w-full relative">
            <p className='warningLine' 
            style={
                {textAlign:"center",
                   backgroundColor:"#F2ECF6",
                   padding:"10px" ,
                   color:"#000000"
                }
            }
            > Want to open app?  <button
            style={{
                backgroundColor:"#8a2be2",
                borderRadius:"5px",
                fontSize:"12px",
                padding:"1px 5px 1px 5px",
                color:"#ffffff"
            }}
            onClick={openAppInInstagram} className="text-lg font-semibold">
                        Open App
                    </button> </p>
            <Header />

            {/* Show Open App Button only if inside Instagram WebView */}
            {/* {isInstagramWebview && ( */}
                {/* <div className="fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-6 py-2 rounded-lg shadow-lg text-center z-50"> */}
                    {/* <button onClick={openAppInInstagram} className="text-lg font-semibold">
                        Open in E-Closet App
                    </button> */}
                    {/* <br /> */}
                    {/* <a href="" className="text-sm underline">
                        Open in Safari/Chrome
                    </a> */}
                {/* </div> */}
            {/* )} */}

        </div>
    );
};

export default User;
