import React from 'react'
import recycle from '../assets/recycle.png'
import founder from '../assets/founder.png'
import { Fade } from "react-awesome-reveal";


function AboutUs() {
    return (
        <div id="about" className='h-full w-full font-poppins'>


            <h1 className='text-primary bg-secondary font-bold text-4xl py-8 text-center'>About Us</h1>
            <Fade triggerOnce direction='right'>

                <div className='h-full w-full gap-8 flex flex-col md:flex-row justify-center items-center px-8 md:px-12 py-16'>

                    <div className='flex flex-col justify-center gap-6 items-center w-full md:w-3/4'>

                        <div className='flex justify-center md:justify-start w-full items-center gap-4'>
                            <h1 className='text-primary font-semibold text-3xl'>Our Mission</h1>
                            <img src={recycle} alt='recycle' className='w-10 h-10' />
                        </div>

                        <p className='text-sm lg:text-xl'>
                            E Closet's mission is to transform the fashion industry by supporting sustainability and accessibility. Over $500 billion in clothing value is lost every year due to under-wearing and failure to recycle clothes as a result of fast fashion. We believe E Closet can reduce fashion waste by providing a platform connecting users in local communities; embracing the reuse of clothing while still meeting your fashion desires. We hope to create a more sustainable future; one closet at a time.
                        </p>
                    </div>

                    <div className='w-full md:w-1/4 flex flex-col gap-2 md:gap-4 justify-center items-center'>
                        <h1 className='font-semibold text-xl'>Brandon Spencer</h1>
                        <h1>CEO & Founder</h1>
                        <img src={founder} alt='founder' className='h-48' />
                    </div>

                </div>

            </Fade>
        </div>
    )
}

export default AboutUs